import { IConnectionAttempt } from 'global/interfaces/connectionAttempt';
import { authorizedGet, authorizedPut } from './baseApiService';

const resource = 'ConnectionAttempt';

export async function getConnectionAttempts(): Promise<IConnectionAttempt[]> {
  return await authorizedGet<IConnectionAttempt[]>(`${resource}`);
}

export async function approveAndSafe(connectionAttemptId: string) {
  return await authorizedPut(`${resource}/approve-and-safe?connectionAttemptId=${connectionAttemptId}`, {});
}

export async function approve(connectionAttemptId: string) {
  return await authorizedPut(`${resource}/approve?connectionAttemptId=${connectionAttemptId}`, {});
}

export async function reject(connectionAttemptId: string) {
  return await authorizedPut(`${resource}/reject?connectionAttemptId=${connectionAttemptId}`, {});
}

export async function rejectAndLock(connectionAttemptId: string) {
  return await authorizedPut(`${resource}/reject-and-lock?connectionAttemptId=${connectionAttemptId}`, {});
}
