import { AuthContext } from 'contexts/AuthContext';
import { beginCheckoutEvent } from 'global/constants';
import { useContext } from 'react';
import { createProductCheckout } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { pushToDataLayer } from 'utils/tagHelper';

type UseSubscriptionSessionReturn = {
  createSubscriptionSession: () => void;
};

export const useSubscriptionSession = (
  showProgress: (show?: boolean | undefined) => void,
): UseSubscriptionSessionReturn => {
  const authContext = useContext(AuthContext);
  const createSubscriptionSession = () => {
    showProgress(true);
    pushToDataLayer(beginCheckoutEvent, {
      transaction_id: authContext.user?.id,
    });
    createProductCheckout(`${window.location.href}`)
      .then(res => {
        window.location.href = res;
      })
      .catch(error => {
        showError(error);
        showProgress(false);
      });
  };

  return { createSubscriptionSession };
};
