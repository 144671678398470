import {
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CountrySelect from 'components/common/Select/CountrySelect';
import { FormikProps } from 'formik';
import { ISignUpForm } from 'global/interfaces/signup';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { WelcomeGraphicContainer } from 'components/common/WelcomeGraphicContainer';
import { NavigateNextOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { AuthForm } from 'components/common/form/AuthForm';
import { AccountTypeOptions } from 'global/enums/accountTypeOptions';
import { AccountSearchParam, EmailSearchParam, ReferralIdSearchParam } from 'global/Constants/SearchParamConstants';
import { nameof } from 'ts-simple-nameof';
import { redirectToLogin } from 'utils/router';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';

export interface SignUpFormProps {
  form: FormikProps<ISignUpForm>;
  isSignUpSubmitting: boolean;
  redirectToSellerId?: string;
  opportunityId?: string;
}

export const SignupForm = ({ form, isSignUpSubmitting, redirectToSellerId, opportunityId }: SignUpFormProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleCountryChange = (event: React.SyntheticEvent, value: { label: string; code: string } | null): void => {
    form.setFieldValue('country', value?.code, true);
  };

  const [searchParams] = useSearchParams();
  const accountType: string | null = searchParams.get(AccountSearchParam);
  const initialEmail = searchParams.get(EmailSearchParam) || '';
  const referralId = searchParams.get(ReferralIdSearchParam) || null;
  const location = useLocation();
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  useEffect(() => {
    if (initialEmail) {
      form.setFieldValue(
        nameof<ISignUpForm>(x => x.email),
        initialEmail,
      );
    }

    if (referralId) {
      form.setFieldValue(
        nameof<ISignUpForm>(x => x.referralID),
        referralId,
      );
    }
  }, [initialEmail, referralId]);
  const handleLoginClick = () => {
    if (redirectToSellerId || opportunityId) {
      location.state = {
        ...location.state,
        sellerId: redirectToSellerId,
        opportunityId: opportunityId,
      };
      redirectToLogin(navigate, location);
    } else {
      navigate('/login');
    }
  };

  return (
    <WelcomeGraphicContainer>
      <AuthForm onSubmit={form.handleSubmit}>
        {accountType !== null && parseInt(accountType) === AccountTypeOptions.Seller ? (
          <>
            {' '}
            <Typography textAlign="center" variant={isMobile ? 'h5' : 'h4'}>
              Create Your Account
            </Typography>
            <Typography variant={isMobile ? 'body2' : 'body1'} mt={3} textAlign="center">
              Free accounts allow you to view opportunities and get listed on the marketplace. You’ll need a premium
              account for {localCurrencySymbol}
              {price} per month to apply for opportunities and create contracts.{' '}
              <Link to={'/pricing'} style={{ color: theme.palette.primary[600] }}>
                Learn more
              </Link>
              .
            </Typography>
          </>
        ) : (
          <>
            <Typography textAlign="center" variant={isMobile ? 'h5' : 'h4'} mb={2}>
              Create Your Account
            </Typography>
          </>
        )}
        <Grid container spacing={3} marginTop={0}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControl variant="standard">
                <TextField
                  size={isMobile ? 'small' : 'medium'}
                  name="email"
                  label="Email *"
                  variant="outlined"
                  type="email"
                  value={form.values.email}
                  onChange={form.handleChange}
                  error={form.touched.email !== undefined && Boolean(form.errors.email)}
                  helperText={form.touched.email !== undefined ? form.errors.email : ''}
                  autoComplete="username"
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControl variant="standard">
                <TextField
                  size={isMobile ? 'small' : 'medium'}
                  name="firstName"
                  label="First Name *"
                  variant="outlined"
                  onChange={form.handleChange}
                  error={form.touched.firstName !== undefined && Boolean(form.errors.firstName)}
                  helperText={form.touched.firstName !== undefined ? form.errors.firstName : ''}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControl variant="standard">
                <TextField
                  size={isMobile ? 'small' : 'medium'}
                  name="lastName"
                  label="Last Name *"
                  variant="outlined"
                  onChange={form.handleChange}
                  error={form.touched.lastName !== undefined && Boolean(form.errors.lastName)}
                  helperText={form.touched.lastName !== undefined ? form.errors.lastName : ''}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControl variant="standard">
                <CountrySelect
                  label="Country *"
                  size={isMobile ? 'small' : 'medium'}
                  onChange={handleCountryChange}
                  value={form.values.country}
                  error={form.touched.country !== undefined && Boolean(form.errors.country)}
                  helperText={form.touched.country !== undefined ? form.errors.country : ''}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControl variant="standard">
                <TextField
                  size={isMobile ? 'small' : 'medium'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name="password"
                  label="Password *"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  onChange={form.handleChange}
                  error={form.touched.password !== undefined && Boolean(form.errors.password)}
                  helperText={form.touched.password !== undefined ? form.errors.password : ''}
                  autoComplete="new-password"
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControl variant="standard">
                <TextField
                  size={isMobile ? 'small' : 'medium'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name="confirmPassword"
                  label="Confirm Password *"
                  variant="outlined"
                  type={showConfirmPassword ? 'text' : 'password'}
                  onChange={form.handleChange}
                  error={form.touched.confirmPassword !== undefined && Boolean(form.errors.confirmPassword)}
                  helperText={form.touched.confirmPassword !== undefined ? form.errors.confirmPassword : ''}
                  autoComplete="new-password"
                />
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>
        <Stack alignItems={'center'} direction={'row'} marginTop={{ xs: 6, sm: 8 }} gap={2}>
          <RoundButton variant="outlined" fullWidth size="medium" onClick={() => navigate(-1)}>
            Back
          </RoundButton>
          <RoundButton
            endIcon={<NavigateNextOutlined />}
            variant="contained"
            size="medium"
            fullWidth
            type="submit"
            loading={isSignUpSubmitting}
          >
            Next
          </RoundButton>
        </Stack>
        <Stack alignItems={'center'} direction={'row'} marginTop={{ xs: 4 }} gap={2}>
          <RoundButton
            variant="text"
            color="inherit"
            type="button"
            size={isMobile ? 'small' : 'large'}
            onClick={handleLoginClick}
            fullWidth
          >
            Already have an account? Sign in
          </RoundButton>
        </Stack>
      </AuthForm>
    </WelcomeGraphicContainer>
  );
};
