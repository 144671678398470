import { Box, Button, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthContext } from 'contexts/AuthContext';
import { FormikProps } from 'formik';
import { ISignUpForm } from 'global/interfaces/signup';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { WelcomeGraphicContainer } from 'components/common/WelcomeGraphicContainer';
import { createUser, validateEmailCode } from 'services/userService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RoundButton } from 'components/common/Button/RoundButton';
import { pushToDataLayer } from 'utils/tagHelper';
import { buyerSignUpEvent, connectionRequestEvent, sellerSingUpEvent } from 'global/constants';
import { getOrCreateConnectionToUser } from 'services/connectionService';
import { IConnection } from 'global/interfaces/connection';
import { showError } from 'utils/errorHandler';
import IApiError from 'global/interfaces/api';
import { AccountSearchParam } from 'global/Constants/SearchParamConstants';
import { nameof } from 'ts-simple-nameof';
import { AccountTypeOptions } from 'global/enums/accountTypeOptions';

export interface AccountVerificationProps {
  form: FormikProps<ISignUpForm>;
  codeError: boolean;
  setCodeError: React.Dispatch<boolean>;
  handleBackClick: () => void;
  redirectToSellerId?: string;
  opportunityId?: string;
}

interface MobileProps {
  isMobile: boolean;
}

const ContainerBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isMobile',
})<MobileProps>(({ isMobile }) => ({
  paddingLeft: isMobile ? '12px' : '24px',
  paddingRight: isMobile ? '12px' : '24px',
  maxWidth: '600px',
}));

const StyledTextField = styled(TextField, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isMobile',
})<MobileProps>(({ isMobile, theme }) => ({
  width: isMobile ? '36px' : '50px',

  '& input': {
    textAlign: 'center',
    padding: '16.5px 14px',

    [theme.breakpoints.down('sm')]: {
      padding: '8px 10px',
    },
  },
}));

const StyledResetButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary[600],
  marginLeft: '-16px',

  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.light,
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '18px 36px',
  borderRadius: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: '12px 22px',
  },
}));

const codeLength = 6;
const emptyCodeValue = -1;

export const AccountVerification = ({
  form,
  handleBackClick,
  redirectToSellerId,
  opportunityId,
}: AccountVerificationProps) => {
  const [code, setCode] = useState<number[]>(new Array(codeLength).fill(emptyCodeValue));
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [resendConfirmation, setResendConfirmation] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const accountTypeParam = searchParams.get(AccountSearchParam);
  const accountType = accountTypeParam ? parseInt(accountTypeParam) : null;

  useEffect(() => {
    if (accountType) {
      form.setFieldValue(
        nameof<ISignUpForm>(x => x.accountType),
        accountType,
      );
    }
  }, [accountType]);

  const textfieldRefs: React.RefObject<HTMLInputElement>[] = Array.from(
    {
      length: codeLength,
    },
    () => useRef<HTMLInputElement>(null),
  );

  const changeCode = (value: string, index: number): void => {
    const newCode = [...code];
    const inputValue = value;
    const length = Math.min(Math.max(inputValue.length, 1), codeLength - index);
    for (let i = 0; i < length; i++) {
      const char = inputValue.charAt(i);
      const number = '0' <= char && char <= '9' ? parseInt(char) : emptyCodeValue;
      newCode[i + index] = number;
    }

    const last = length + index - 1;
    if (newCode[last] !== emptyCodeValue) {
      const nextInput = textfieldRefs[last + 1]?.current;
      nextInput?.focus();
      nextInput?.select();
    }

    setCode(newCode);
    checkCodeIsValid(newCode);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    changeCode(e.clipboardData.getData('text'), index);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number): void => {
    changeCode(e.target.value, index);
  };

  const isValid = (code: number[]) => {
    const numberValues = code.filter(x => x !== emptyCodeValue);
    return numberValues.length == codeLength;
  };

  const checkCodeIsValid = async (newCode: number[]): Promise<void> => {
    if (isValid(newCode)) {
      const validCode = newCode.reduce((accum, digit) => accum * 10 + digit, 0);

      validateEmailCode(form.values.email, validCode)
        .then(isValid => setError(!isValid))
        .catch(() => setError(true));
    }
  };

  const connectToUserAndRedirectToWorkRoom = (otherUserId: string) => {
    getOrCreateConnectionToUser(otherUserId)
      .then((connection: IConnection) => {
        pushToDataLayer(connectionRequestEvent);
        setLoading(false);
        navigate(`/workroom/${connection.chatThreadId}`);
      })
      .catch((err: IApiError) => {
        showError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSignUp = async (): Promise<void> => {
    if (isValid(code)) {
      const codeNumber = code.reduce((accum, digit) => accum * 10 + digit, 0);

      const data = form.values;
      data.confirmationCode = codeNumber;
      setLoading(true);

      createUser(data)
        .then(newUserId => {
          authContext.refresh().then(() => {
            setLoading(false);
            if (accountType === AccountTypeOptions.Buyer) {
              pushToDataLayer(buyerSignUpEvent);
            } else {
              pushToDataLayer(sellerSingUpEvent);
            }

            if (data.referralID) {
              connectToUserAndRedirectToWorkRoom(data.referralID);
            } else if (redirectToSellerId) {
              const url = opportunityId
                ? `/users/${redirectToSellerId}?opportunityId=${opportunityId}`
                : `/users/${redirectToSellerId}`;
              navigate(url);
            } else if (accountType === AccountTypeOptions.Seller) {
              navigate(`/users/${newUserId}/created`);
            } else {
              navigate(`/`);
            }
          });
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    } else {
      setError(true);
    }
  };

  const renderCodeBox = (index: number) => (
    <Grid item key={`codebox-${index}`}>
      <StyledTextField
        inputProps={{
          maxLength: 1,
        }}
        value={code[index] < 0 ? '' : code[index]}
        variant="outlined"
        onChange={e => handleChange(e, index)}
        onPaste={e => handlePaste(e, index)}
        inputRef={textfieldRefs[index]}
        disabled={loading}
        isMobile={isMobile}
        autoComplete="one-time-code"
      />
    </Grid>
  );

  //TODO - Need smaller padding on mobile screens, test iPhone SE
  return (
    <WelcomeGraphicContainer>
      <ContainerBox isMobile={isMobile}>
        <Typography textAlign="center" variant={isMobile ? 'h5' : 'h4'} color={theme.palette.grey[900]} marginTop={1}>
          Verify Your Email Address
        </Typography>
        <Typography textAlign="center" variant="body1" color={theme.palette.grey[500]} marginTop={3}>
          We&apos;ve sent you a code to verify your email address. Check you spam folder if you cannot find it and add
          support@shoutt.co to your contacts.
        </Typography>

        <Grid container spacing={1} marginTop={2} justifyContent={'center'} flexWrap={'nowrap'}>
          {[...Array(codeLength).keys()].map(index => renderCodeBox(index))}
        </Grid>
        {error && (
          <Box marginTop={2} textAlign="center">
            <Typography variant="subtitle2" color="error">
              Verification code does not match
            </Typography>
          </Box>
        )}

        <Box marginTop={2} textAlign="center">
          <StyledResetButton
            size="medium"
            variant="text"
            onClick={() => {
              form.handleSubmit();
              setResendConfirmation(true);
              setTimeout(() => {
                setResendConfirmation(false);
              }, 3000);
            }}
          >
            Resend code
          </StyledResetButton>
        </Box>
        {resendConfirmation && (
          <Box marginTop={1} textAlign="center">
            <Typography variant="body1" component="span" color={theme.palette.grey[500]}>
              Your Verification code has been resent
            </Typography>
            <CheckCircleIcon color="success" sx={{ ml: 1, mb: -0.5 }} />
          </Box>
        )}
        <Box marginTop={2} textAlign="center">
          <Typography variant="caption" color={theme.palette.grey[500]}>
            By creating an account, you accept our{' '}
            <Link to={'/terms'} target="_blank" style={{ color: theme.palette.primary[600] }}>
              terms and conditions
            </Link>
            &nbsp; and &nbsp;
            <Link to="/privacy" target="_blank" style={{ color: theme.palette.primary[600] }}>
              privacy policy.
            </Link>
          </Typography>
        </Box>
        <Stack alignItems={'center'} direction={'row'} marginTop={{ xs: 6, sm: 8 }} gap={2}>
          <RoundButton variant="outlined" fullWidth size="medium" onClick={handleBackClick}>
            Back
          </RoundButton>
          <StyledLoadingButton variant="contained" size="medium" fullWidth onClick={handleSignUp} loading={loading}>
            Sign Up
          </StyledLoadingButton>
        </Stack>
      </ContainerBox>
    </WelcomeGraphicContainer>
  );
};
