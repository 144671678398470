import { SvgIcon, SvgIconProps } from '@mui/material';

export const CommissionIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 20" fill="none" {...props}>
    <path
      d="M13.703 5.47637C12.6243 3.75861 11.2354 2.28562 10.0191 1.0714C9.94435 0.996773 9.8528 0.941169 9.75213 0.90926C9.65146 0.877352 9.54461 0.870071 9.44054 0.888029C9.33647 0.905988 9.23823 0.948661 9.15408 1.01246C9.06992 1.07627 9.00231 1.15933 8.95692 1.25469L6.41175 6.59955L3.74899 4.82435C3.67677 4.77619 3.59576 4.74276 3.5106 4.72596C3.42544 4.70917 3.3378 4.70933 3.2527 4.72646C3.16761 4.74358 3.08673 4.77732 3.01469 4.82575C2.94266 4.87418 2.88088 4.93634 2.83291 5.00868C1.22512 7.43236 0.409912 9.62699 0.409912 11.5315C0.409912 13.5445 1.20957 15.475 2.63297 16.8984C4.05637 18.3218 5.98692 19.1215 7.99991 19.1215C10.0129 19.1215 11.9435 18.3218 13.3669 16.8984C14.7903 15.475 15.5899 13.5445 15.5899 11.5315C15.5899 9.47907 14.9727 7.49843 13.703 5.47637ZM12.8917 12.2922C12.7341 13.3315 12.2482 14.2931 11.5049 15.0364C10.7616 15.7797 9.8 16.2657 8.7607 16.4233C8.7271 16.4285 8.69314 16.4311 8.65914 16.4311C8.49291 16.4309 8.33289 16.368 8.21104 16.2549C8.08919 16.1419 8.01449 15.987 8.00187 15.8212C7.98925 15.6555 8.03963 15.4911 8.14295 15.3608C8.24627 15.2306 8.39492 15.1442 8.5592 15.1188C9.31817 14.9965 10.0193 14.6381 10.5629 14.0945C11.1065 13.5509 11.4649 12.8498 11.5872 12.0908C11.6004 12.0051 11.6304 11.9229 11.6754 11.8488C11.7204 11.7748 11.7795 11.7103 11.8494 11.6591C11.9193 11.6078 11.9987 11.5709 12.0829 11.5503C12.1671 11.5298 12.2545 11.526 12.3401 11.5392C12.4258 11.5525 12.508 11.5824 12.5821 11.6274C12.6562 11.6724 12.7207 11.7316 12.7719 11.8015C12.8231 11.8714 12.86 11.9507 12.8806 12.0349C12.9011 12.1191 12.9049 12.2065 12.8917 12.2922H12.8917Z"
      fill="#4B56D8"
    />
  </SvgIcon>
);
