import { Box, FormControl, FormGroup, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import ProfileImageEditor from 'components/userProfileEdits/sections/EditUserHeadSection/ProfileImageEditor';
import { AuthContext } from 'contexts/AuthContext';
import { useFormik } from 'formik';
import IApiError from 'global/interfaces/api';
import { IBuyerProfile, IUser } from 'global/interfaces/user';
import { editBuyerValidation } from 'global/validations/user';
import { Dispatch, useContext, useState } from 'react';
import { getUser, upsertUser } from 'services/userService';
import { showError } from 'utils/errorHandler';
import { RoundButton } from './Button/RoundButton';
import { nameof } from 'ts-simple-nameof';
import { StyledDialogActions } from './Dialog';
import { handleProfileImgChange } from 'utils/file';
import { BuyerPlaceholderTitleText, SellerPlaceholderTitleText } from 'global/constants';

interface ICompleteProfileProps {
  handleClose?: () => void;
  handleComplete?: () => void;
  isDialog?: boolean;
  currentUser?: IUser | null;
  isProfileDetailsInValid: boolean;
  noRefreshContext?: boolean;
  setProfileDetailsInValid?: Dispatch<boolean>;
  setShouldRefreshUser?: Dispatch<boolean>;
  setConnectionDrawerOpen?: Dispatch<boolean>;
}

export function CompleteProfileForm({
  handleClose,
  handleComplete,
  currentUser,
  isProfileDetailsInValid,
  noRefreshContext,
  setProfileDetailsInValid,
  setShouldRefreshUser,
  setConnectionDrawerOpen,
}: ICompleteProfileProps) {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);

  const completeUpdateUser = () => {
    if (setConnectionDrawerOpen) {
      setProfileDetailsInValid && setProfileDetailsInValid(false);
      setLoading(false);
      setConnectionDrawerOpen(true);
    } else {
      setLoading(false);
      setProfileDetailsInValid && setProfileDetailsInValid(false);
      setShouldRefreshUser && setShouldRefreshUser(true);
      handleComplete && handleComplete();
    }
  };

  const form = useFormik<IBuyerProfile>({
    initialValues: {
      title: currentUser?.title ?? '',
      profileImage: currentUser?.profileImage ?? undefined,
    },
    onSubmit: values => {
      setLoading(true);
      let currentUser: IUser;

      getUser()
        .then((res: IUser) => {
          currentUser = res;
          currentUser.title = values.title;
          currentUser.profileImage = values.profileImage;
          currentUser.isDraftSave = true;

          upsertUser(currentUser)
            .then(() => {
              if (!noRefreshContext) {
                authContext.refresh().then(() => {
                  completeUpdateUser();
                });
              } else {
                completeUpdateUser();
              }
            })
            .catch((err: IApiError) => {
              setLoading(false);
              showError(err);
            });
        })
        .catch((err: IApiError) => {
          if (err.status !== 404) {
            showError(err);
            setLoading(false);
          }
        });
    },
    validationSchema: editBuyerValidation,
  });

  const profilePath = form.values.profileImage ? form.values.profileImage.path : '';

  return (
    <Box>
      <Grid container item xs={12} pt={'24px'} spacing={3} justifyContent="center">
        {isProfileDetailsInValid && (
          <>
            <Grid item xs={11}>
              <FormGroup>
                <FormControl>
                  <TextField
                    fullWidth
                    name={nameof<IBuyerProfile>(x => x.title)}
                    value={form.values.title ?? ''}
                    onChange={form.handleChange}
                    label="Profile Title *"
                    variant="outlined"
                    placeholder={
                      authContext.user?.isSellingServices ? SellerPlaceholderTitleText : BuyerPlaceholderTitleText
                    }
                    error={form.touched.title && Boolean(form.errors.title)}
                    helperText={form.touched.title ? form.errors.title : ''}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={11}>
              <ProfileImageEditor
                error={form.errors.profileImage ? 'Profile image is required' : ''}
                isTouched={form.touched.profileImage !== undefined}
                imagePath={profilePath}
                isSellingServices={authContext.user?.isSellingServices ?? true}
                handleProfileImgChange={(file: File) => handleProfileImgChange(file, form)}
              />
            </Grid>
          </>
        )}
      </Grid>
      <>
        <StyledDialogActions sx={{ marginTop: '8px' }}>
          <Grid container justifyContent="flex-end" gap={2}>
            <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={handleClose}>
              Cancel
            </RoundButton>

            <RoundButton
              loading={loading}
              fullWidth={isMobileScreen}
              variant="contained"
              onClick={() => form.handleSubmit()}
            >
              Save and Continue
            </RoundButton>
          </Grid>
        </StyledDialogActions>
      </>
    </Box>
  );
}
