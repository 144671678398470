import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { IOpportunity, OpportunityStatus } from 'global/interfaces/opportunity';
import { Dispatch, useState } from 'react';
import { updateOpportunityStatus } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';

export enum OppAdminAction {
  Approve,
  Edit,
  Reject,
}

interface IOppAdminConfirmationDialog {
  open: boolean;
  setOpen: Dispatch<boolean>;
  setRefresh: Dispatch<boolean>;
  opportunity?: IOpportunity;
  action?: OppAdminAction;
}

export function OppAdminConfirmationDialog({
  open,
  setOpen,
  opportunity,
  action,
  setRefresh,
}: IOppAdminConfirmationDialog) {
  const [loading, setLoading] = useState(false);
  let title = '';
  let content = '';
  let actionBtnText = '';
  let btnColor: 'primary' | 'error' = 'primary';
  let btnVariant: 'contained' | 'outlined' = 'contained';
  let actionFunc: () => void;

  const completeAction = (status: OpportunityStatus) => {
    if (!opportunity?.id) return;

    setLoading(true);
    updateOpportunityStatus({ opportunityId: opportunity.id, status: status })
      .then(() => setRefresh(true))
      .catch(showError)
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  if (action === OppAdminAction.Approve) {
    title = 'Approve this opportunity?';
    content = 'Are you sure you want to approve this opportunity?';
    actionBtnText = 'Approve';
    actionFunc = () => completeAction(OpportunityStatus.Approved);
  } else if (action === OppAdminAction.Reject) {
    title = 'Reject this opportunity?';
    content = 'Are you sure you want to reject this opportunity?';
    actionBtnText = 'Reject';
    btnColor = 'error';
    btnVariant = 'outlined';
    actionFunc = () => completeAction(OpportunityStatus.Rejected);
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <RoundButton onClick={() => setOpen(false)}>Cancel</RoundButton>
        <RoundButton variant={btnVariant} onClick={() => actionFunc()} autoFocus color={btnColor} loading={loading}>
          {actionBtnText}
        </RoundButton>
      </DialogActions>
    </Dialog>
  );
}
