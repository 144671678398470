import { CircularProgress } from '@mui/material';
import { IOpportunity, OpportunityType } from 'global/interfaces/opportunity';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getOpportunity } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import { UserNotFound } from 'views/users/UserNotFound';
import OpportunityForm from '../../components/opportunities/newOpportunityForm';

export function EditOpportunity() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [opp, setOpp] = useState<IOpportunity>();
  const navigate = useNavigate();

  useTitle('Admin - Edit Opportunity');

  useEffect(() => {
    if (!id) return;

    getOpportunity(id)
      .then(res => setOpp(res))
      .catch(showError)
      .finally(() => setLoading(false));
  }, []);

  if (!loading && !opp) {
    return <UserNotFound />;
  }

  return (
    <>
      {opp ? (
        <OpportunityForm
          setSubmitted={() => navigate('/admin/internal-jobs')}
          isExternal={opp.type === OpportunityType.External}
          opportunity={opp}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
