import { MainSectionBox } from './MainSectionBox';
import { Box, Button, CircularProgress, Grow, Stack, styled, Typography, useTheme } from '@mui/material';
import { AccessTime, ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { showError } from 'utils/errorHandler';
import { useIsComponentMounted } from 'global/hooks/useIsComponentMounted';
import { IOpportunity } from 'global/interfaces/opportunity';
import { getOpportunities } from 'services/opportunityService';
import { EllipsisTypography } from 'components/common/Typography/EllipsisTypography';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export default function ItemLatestOpportunities() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const isComponentMounted = useIsComponentMounted();

  useEffect(() => {
    loadOpportunities();
  }, []);

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewOpportunity],
    (res: IOpportunity) => {
      setOpportunities(current => [res, ...current.slice(0, current.length - 1)]);
    },
    [],
  );

  const loadOpportunities = () => {
    if (isComponentMounted.current) {
      setLoading(true);
      setOpportunities([]);
    }

    getOpportunities()
      .then((res: IOpportunity[]) => {
        if (res.length > 6) res = res.slice(0, 6);
        if (isComponentMounted.current) {
          setOpportunities(res);
          setLoading(false);
        }
      })
      .catch(showError);
  };
  return (
    <MainSectionBox>
      <JustifiedBox>
        <Stack direction="row" spacing={1.5}>
          <Typography variant="subtitle1" fontWeight={600}>
            Latest Opportunities
          </Typography>
        </Stack>
        {!loading && opportunities.length > 0 && (
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              sx={{ color: theme.palette.primary.dark, mr: '-6px' }}
              endIcon={<ArrowForward />}
              onClick={() => navigate(`/opportunities`)}
            >
              View all
            </Button>
          </Stack>
        )}
      </JustifiedBox>
      {loading && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      {!loading && opportunities.length === 0 && (
        <Box display={'flex'}>
          <Stack direction="column" spacing={2} mb={2} mt={1}>
            <Typography variant="body2">No new opportunities!</Typography>
          </Stack>
        </Box>
      )}
      {!loading && opportunities.length > 0 && (
        <Grow in={true} timeout={500}>
          <Box mb={2}>
            {opportunities.map((opportunity, index: number) => (
              <Stack key={index} direction="column" spacing={1.5} mt={3.0}>
                <JustifiedBox>
                  <FlexBox maxWidth={'calc(100% - 100px)'}>
                    <EllipsisTypography variant="subtitle2" title={opportunity.title}>
                      {opportunity.title}
                    </EllipsisTypography>
                  </FlexBox>
                  <FlexBox>
                    <Stack direction={'row'} gap={0.5} width={'90px'}>
                      <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
                      <Typography variant="body2" color={theme.palette.grey[500]}>
                        {opportunity.agoDesc}
                      </Typography>
                    </Stack>
                  </FlexBox>
                </JustifiedBox>
              </Stack>
            ))}
          </Box>
        </Grow>
      )}
    </MainSectionBox>
  );
}
