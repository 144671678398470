import { NewOpportunityConfirmation } from 'components/opportunities/newOpportunityConfirmation';
import OpportunityForm from 'components/opportunities/newOpportunityForm';
import { useState } from 'react';
import { useTitle } from 'utils/router';

export default function NewJob() {
  useTitle('Post an Opportunity');
  const [submitted, setSubmitted] = useState(false);
  return (
    <>
      {!submitted ? <OpportunityForm setSubmitted={setSubmitted} isExternal={false} /> : <NewOpportunityConfirmation />}
    </>
  );
}
