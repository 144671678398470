import { useTheme } from '@mui/material/styles';

import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import {
  BannerStack,
  FeatureSubText,
  FeatureText,
  MultiLineFeatureContainer,
  StyledAvatar,
  StyledBannerBox,
} from 'components/info/Hero';
import { desktopHeaderHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';
import UserSearch from 'components/common/Search/UserSearch';
import { ClockIcon } from 'components/icon/ClockIcon';
import { PaymentProtectionIcon } from 'components/icon/PaymentProtectionIcon';
import { CommissionIcon } from 'components/icon/CommissionIcon';

const HeaderBanner = () => {
  const location = useLocation();
  const showHeaderGraphic = location.pathname.toLowerCase() === '/talent';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <>
      {showHeaderGraphic && (
        <>
          {isMobile && (
            <Box mb="12px" mt={`${mobileHeaderHeight + 12}px`} paddingX="16px">
              <UserSearch />
            </Box>
          )}
          <StyledBannerBox marginTop={!isMobile ? `${desktopHeaderHeight}px` : '0px'}>
            <Container maxWidth="lg">
              <BannerStack direction={'column'} rowGap={2} alignItems={isMobile ? 'flex-start' : 'center'}>
                <Typography
                  variant="h3"
                  textAlign={isMobile ? 'left' : 'center'}
                  fontWeight={700}
                  fontSize={{ xs: '28px', md: '40px', lg: '56px' }}
                  color={theme.palette.common.black}
                  maxWidth={'800px'}
                >
                  The fastest way to hire quality freelancers
                </Typography>
                <Grid container mt={{ xs: '0px', md: '32px' }} spacing={isMobile ? '14px' : '18px'}>
                  <Grid item xs={12} md={4}>
                    <MultiLineFeatureContainer direction={'row'}>
                      <StyledAvatar large={!isMobile}>
                        <ClockIcon sx={{ width: !isMobile ? '25px' : '20px', height: !isMobile ? '25px' : '20px' }} />
                      </StyledAvatar>
                      <Stack direction={'column'} gap={'4px'}>
                        <FeatureText>Save Your Time</FeatureText>
                        <FeatureSubText>
                          {isMobile ? (
                            <>
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  color: theme.palette.primary.dark,
                                  cursor: 'pointer',
                                }}
                                onClick={() => navigate('/newjob')}
                              >
                                Post an opportunity
                              </span>
                            </>
                          ) : (
                            <>Post an opportunity </>
                          )}{' '}
                          or browse quality talent that has been pre-screened
                        </FeatureSubText>
                      </Stack>
                    </MultiLineFeatureContainer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MultiLineFeatureContainer direction={'row'}>
                      <StyledAvatar large={!isMobile}>
                        <CommissionIcon
                          sx={{ width: !isMobile ? '25px' : '20px', height: !isMobile ? '25px' : '20px' }}
                        />
                      </StyledAvatar>
                      <Stack direction={'column'} gap={'4px'}>
                        <FeatureText>No Commissions</FeatureText>
                        <FeatureSubText>
                          No more large comissions! All transactions are 100% commission free
                        </FeatureSubText>
                      </Stack>
                    </MultiLineFeatureContainer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MultiLineFeatureContainer direction={'row'}>
                      <StyledAvatar large={!isMobile}>
                        <PaymentProtectionIcon
                          sx={{ width: !isMobile ? '25px' : '20px', height: !isMobile ? '25px' : '20px' }}
                        />
                      </StyledAvatar>
                      <Stack direction={'column'} gap={'4px'}>
                        <FeatureText>Payment Protection</FeatureText>
                        <FeatureSubText>
                          Hire freelancers on platform and benefit from payment protection
                        </FeatureSubText>
                      </Stack>
                    </MultiLineFeatureContainer>
                  </Grid>
                </Grid>
              </BannerStack>
            </Container>
          </StyledBannerBox>
        </>
      )}
    </>
  );
};

export default HeaderBanner;
