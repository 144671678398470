import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { showError } from 'utils/errorHandler';
import { getContract, updateContract } from 'services/contractService';
import { ContractType, IContract, IContractDisplay } from 'global/interfaces/contract';
import IApiError from 'global/interfaces/api';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTitle } from 'utils/router';
import { ContractDisplay } from 'components/common/Contract/contractDisplay';
import styled from '@emotion/styled';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';

const StyledGrid = styled(Grid)(() => ({
  marginBottom: '50px',

  '& .contractDrawerBody': {
    paddingInline: 0,
  },
}));

function ViewContract() {
  useTitle('Contract Details');
  const urlParams = useParams();
  const { id } = urlParams;
  const contractId = id ?? '';
  const [searchParams] = useSearchParams();
  const successfulPaymentRedirect = searchParams.get('successfulPayment') === 'true';
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const [contract, setContract] = React.useState<IContract | null>(null);
  const navigate = useNavigate();

  const handleWorkRoomClick = (contract: IContract): void => {
    navigate(`/workroom/${contract.chatThreadId}`);
  };

  // Get seller on page load
  useEffect(() => {
    getContract(contractId)
      .then((res: IContractDisplay) => {
        updateContract(res);
        setContract(res);
      })
      .catch((err: IApiError) => {
        if (err.status !== 404) {
          showError(err);
        }
      });
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Box marginTop={isMobile ? 4 : 8}>
          {successfulPaymentRedirect && (
            <>
              <Typography variant="h6" component="div" align="center" sx={{ flexGrow: 1, mb: 3 }}>
                Your payment has been processed
              </Typography>
              <Typography variant="body2" align="left">
                Your payment is being held securely in escrow and will be released to{' '}
                {contract?.buyerAdditionalDetails?.sellerDisplayName}
                {contract?.contractType == ContractType.Retainer && ' on the agreed schedule.'}
                {contract?.contractType == ContractType.FixedPrice &&
                  contract.endRelease == 1 &&
                  ' once you have approved all the deliverables.'}
                {contract?.contractType == ContractType.FixedPrice &&
                  (contract.endRelease ?? 1) > 0 &&
                  (contract.endRelease ?? 1) < 1 &&
                  `. The first ${(1 - (contract.endRelease ?? 1)) * 100}% will be released up front. The remaining ${
                    (contract.endRelease ?? 1) * 100
                  }% will be released once you have approved all the deliverables.`}
                {contract?.contractType == ContractType.FixedPrice && (contract.endRelease ?? 1) == 0 && ' up front.'}
              </Typography>
            </>
          )}
          {!successfulPaymentRedirect && (
            <Typography variant="h4" component="div" align="center" sx={{ flexGrow: 1, mb: 3 }}>
              Contract Details
            </Typography>
          )}
          <Typography variant="body1" component="div" align="center" sx={{ flexGrow: 1 }} mt="24px">
            <RoundButton color="primary" variant="contained" onClick={() => handleWorkRoomClick(contract!)}>
              Go to Workroom
            </RoundButton>
          </Typography>
          {contract !== null && (
            <StyledGrid>
              <ContractDisplay
                contract={contract}
                hideHeading={true}
                successfulPaymentRedirect={successfulPaymentRedirect}
              />
            </StyledGrid>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default ViewContract;
