import { useLocalization } from 'global/hooks/useLocalization';
import ActionBox from './ActionBox';

import CopyToClipboardButton from 'components/common/Button/CopyToClipboardButton';
import { getSourceUrl } from 'utils/user';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { useTheme } from '@mui/material';

export default function ActionRefer() {
  const { localCurrencySymbol } = useLocalization(19.99);
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  return (
    <ActionBox
      title="Refer a friend"
      body={`Refer a friend and get a ${localCurrencySymbol}20 reward when they sign up to the premium plan.`}
    >
      <CopyToClipboardButton
        textToCopyToClipboard={getSourceUrl(authContext.user?.id ?? '')}
        buttonLabel="Copy link"
        roundButton={true}
        sx={{
          padding: '14px 28px',
          borderRadius: theme.spacing(6),

          [theme.breakpoints.down('sm')]: {
            padding: '12px 22px',
          },
        }}
      />
    </ActionBox>
  );
}
