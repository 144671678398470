import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

interface PriceFeature {
  text: string;
}

const sellerFreePriceFeatures: PriceFeature[] = [
  { text: 'View opportunities' },
  { text: 'Get listed on marketplace' },
  { text: '100% commission free transactions' },
  { text: 'Accept contract offers' },
];

const sellerPremPriceFeatures: PriceFeature[] = [
  { text: 'Apply/respond to opportunities' },
  { text: 'Create fixed price and retainer contracts' },
  { text: 'Seller payment protection' },
  { text: 'Sub-contract work to other freelancers' },
];

const buyerFreePriceFeatures: PriceFeature[] = [
  { text: 'Post opportunities' },
  { text: 'Browse and reach out to talent' },
  { text: 'Pay fixed price contracts' },
];

const buyerPremPriceFeatures: PriceFeature[] = [
  { text: 'Create contract offers' },
  { text: 'Pay retainer contracts' },
  { text: 'Buyer payment protection' },
];

interface PricingTableProps {
  isSeller: boolean;
  currencySybmol: string;
  premPrice: number;
  freeBgColor?: string;
  freeCTA?: React.ReactNode;
  premCTA?: React.ReactNode;
}

const StyledPriceBox = styled(Box)(() => ({
  padding: '40px 32px 40px 32px',
  borderRadius: '24px',
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '100%',
  maxWidth: '500px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

interface StyledFreePriceBoxProps {
  freeBgColor?: string;
}

const StyledFreePriceBox = styled(StyledPriceBox, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'freeBgColor',
})<StyledFreePriceBoxProps>(() => ({
  backgroundColor: '#FFFFFF',
  borderColor: '#FFFFFF',
}));

const StyledPremiumPriceBox = styled(StyledPriceBox)(() => ({
  backgroundColor: 'rgb(231, 226, 254)',
  borderColor: '#FFFFFF',
}));

const PriceTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginTop: '16px',
  fontSize: '40px',

  [theme.breakpoints.up('md')]: {
    fontSize: '60px',
  },
}));

const PriceFeatureTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '140%',
  fontSize: '16px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '20px',
  },
}));

const PriceFreeFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#121F31',
}));

const PricePremFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#121F31',
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#121F31',
  marginRight: '16px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

export default function PricingTable({ currencySybmol, premPrice, freeBgColor, freeCTA, premCTA }: PricingTableProps) {
  return (
    <Grid container spacing={{ xs: 0, md: 4 }} rowSpacing={4}>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-end' }}>
        <StyledFreePriceBox freeBgColor={freeBgColor}>
          <Box mb={2}>
            <Typography variant="h6" fontWeight={600}>
              Free Account
            </Typography>
            <PriceTypography variant="h2">{currencySybmol}0 </PriceTypography>
            <Typography variant="body1" fontWeight={500} color={'#121F31'}>
              per Month
            </Typography>
            <PriceFreeFeatureTypography variant="body1" fontWeight={700} mt={{ xs: 2, md: 3 }}>
              All this for free
            </PriceFreeFeatureTypography>
            <PriceFreeFeatureTypography mt={{ xs: 2, md: 3 }} variant="body1">
              Looking for Work:
            </PriceFreeFeatureTypography>
            <List>
              {sellerFreePriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PriceFreeFeatureTypography variant="body1">{feature.text}</PriceFreeFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <PriceFreeFeatureTypography mt={1} variant="body1">
              Looking for Talent:
            </PriceFreeFeatureTypography>
            <List>
              {buyerFreePriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PriceFreeFeatureTypography variant="body1">{feature.text}</PriceFreeFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          {freeCTA}
        </StyledFreePriceBox>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-start' }}>
        <StyledPremiumPriceBox>
          <Box mb={2}>
            <Typography variant="h6" fontWeight={600}>
              Premium Account
            </Typography>
            <PriceTypography variant="h2">
              {currencySybmol}
              {premPrice}
            </PriceTypography>
            <Typography variant="body1" fontWeight={500}>
              per Month
            </Typography>
            <PricePremFeatureTypography variant="body1" fontWeight={700} mt={{ xs: 2, md: 3 }}>
              Everything in free +
            </PricePremFeatureTypography>
            <PricePremFeatureTypography mt={{ xs: 2, md: 3 }} variant="body1">
              Looking for Work:
            </PricePremFeatureTypography>
            <List>
              {sellerPremPriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <PricePremFeatureTypography mt={1} variant="body1">
              Looking for Talent:
            </PricePremFeatureTypography>
            <List>
              {buyerPremPriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          {premCTA}
        </StyledPremiumPriceBox>
      </Grid>
    </Grid>
  );
}
