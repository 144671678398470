import { authorizedGet, authorizedPost, authorizedPut } from './baseApiService';
import {
  IApplyShouttOpp,
  INewOpportunityForm,
  IOppApplyExternalReponse,
  IOpportunity,
  IOpportunityFilters,
  OpportunityStatus,
} from 'global/interfaces/opportunity';
import { getQueryParams } from 'utils/url';

const resource = 'Opportunity';

export async function postNewOpportunity(opportunityDetails: INewOpportunityForm) {
  return await authorizedPost(`${resource}`, opportunityDetails);
}

export async function getOpportunities(): Promise<IOpportunity[]> {
  return await authorizedGet<IOpportunity[]>(`${resource}`);
}

export async function applyToExternal(opportunityId: string): Promise<IOppApplyExternalReponse> {
  return await authorizedPost<IOppApplyExternalReponse>(
    `${resource}/apply-to-external?opportunityId=${opportunityId}`,
    {},
  );
}

export async function applyToShoutt(applyModel: IApplyShouttOpp): Promise<boolean> {
  return await authorizedPost<boolean>(`${resource}/apply-to-shoutt-opp`, applyModel);
}

export async function opportunityViewed(opportunityId: string) {
  return await authorizedPost(`${resource}/viewed?opportunityId=${opportunityId}`, {});
}

export async function payWalled(opportunityId: string) {
  return await authorizedPost(`${resource}/pay-walled?opportunityId=${opportunityId}`, {});
}

export async function profileNotApproved(opportunityId: string) {
  return await authorizedPost(`${resource}/profile-not-approved?opportunityId=${opportunityId}`, {});
}

export async function messagePoster(opportunityId: string) {
  return await authorizedPost(`${resource}/message-poster?opportunityId=${opportunityId}`, {});
}

export async function getAdminOpportunities(filters: IOpportunityFilters): Promise<IOpportunity[]> {
  return await authorizedGet<IOpportunity[]>(`${resource}/admin?${getQueryParams(filters)}`);
}

export async function updateOpportunityStatus(params: { opportunityId: string; status: OpportunityStatus }) {
  return await authorizedPut<IOpportunity[]>(`${resource}/update-status?${getQueryParams(params)}`, {});
}

export async function getOpportunity(opportunityId: string): Promise<IOpportunity> {
  return await authorizedGet<IOpportunity>(`${resource}/${opportunityId}`);
}

export async function updateOpportunity(opportunityDetails: INewOpportunityForm, opportunityId: string) {
  return await authorizedPut(`${resource}/${opportunityId}`, opportunityDetails);
}
