export const profileContainer = 'profile';

export const ThumbnailExtension = '.jpg';

//Custom tracking event names
export const buyerSignUpEvent = 'buyerSignUp';
export const sellerSingUpEvent = 'sellerSingUp';
export const sellerSubmitProfileEvent = 'sellerSubmitProfile';
export const buyerSubmitProfileEvent = 'buyerSubmitProfile';
export const connectionRequestEvent = 'connectionRequest';
export const beginCheckoutEvent = 'beginCheckout';
export const completedCheckoutEvent = 'completedCheckout';
export const completeBuyerProfileEvent = 'completeBuyerProfile';
export const viewSellerProfile = 'viewSellerProfile';
export const viewBuyerProfile = 'viewBuyerProfile';
export const shareUserProfile = 'shareProfile';
export const viewOpportunity = 'viewOpportunity';
export const postOpportunity = 'postOpportunity';

//Params
export const createContractSearchParam = 'create-contract';
export const adminParam = 'admin';

//Content text
export const SellerPlaceholderTitleText = 'UGC Creator, Digital Marketer, All Round Creative, etc';
export const BuyerPlaceholderTitleText = 'Tech Influencer, Beauty Brand Manager, etc';

//URLs
export const helpUrl = 'https://help.shoutt.co/';
