import { Box, CircularProgress, Container, Grid, SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import FormSelect from 'components/common/Select/FormSelect';
import { processOpportunityCategories } from 'components/opportunities/categories';
import {
  OppAdminAction,
  OppAdminConfirmationDialog,
} from 'components/opportunities/internal/OppAdminConfirmationDialog';
import OppFilters from 'components/opportunities/OppFilters';
import OpportunityAccordion from 'components/opportunities/OpportunityAccordion';
import { IOpportunity, IOpportunityFilters, OpportunityStatus } from 'global/interfaces/opportunity';
import { SelectItem } from 'global/interfaces/selects';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAdminOpportunities } from 'services/opportunityService';
import { nameof } from 'ts-simple-nameof';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import { mapToSearchParams } from 'utils/search';

const mapToFilters = (searchParams: URLSearchParams): IOpportunityFilters => {
  const statusString = searchParams.get(nameof<IOpportunityFilters>(p => p.status));
  return {
    status: OpportunityStatus[statusString as keyof typeof OpportunityStatus] ?? undefined,
  };
};

export default function ViewAdminOpporunities() {
  useTitle('Admin - Manage Opportunities');
  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const [filteredOpps, setFilteredOpps] = useState<IOpportunity[]>([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [category, setCategory] = useState<string>('All');
  const [categories, setCategories] = useState<SelectItem[]>([
    {
      id: 'All',
      label: 'All',
    },
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<IOpportunityFilters>(
    searchParams.toString() == ''
      ? {
          status: OpportunityStatus.UnderReview,
        }
      : mapToFilters(searchParams),
  );
  const [open, setOpen] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState<IOpportunity>();
  const [dialogType, setDialogType] = useState<OppAdminAction>();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const processCategories = (retrievedOpps: IOpportunity[]) => {
    const items = processOpportunityCategories(retrievedOpps);
    setCategories(items);
  };

  useEffect(() => {
    processCategories(opportunities);
  }, [opportunities]);

  const fetchOpps = () => {
    getAdminOpportunities(filters)
      .then((retrievedOpps: IOpportunity[]) => {
        setOpportunities(retrievedOpps);
        setFilteredOpps(retrievedOpps);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
        setSearchParams(mapToSearchParams(filters));
        setRefresh(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchOpps();
  }, [filters]);

  useEffect(() => {
    if (refresh) {
      setLoading(true);
      fetchOpps();
    }
  }, [refresh]);

  const statusItems = Object.values(OpportunityStatus).map(os => ({
    id: os.toString(),
    label: os.toString(),
  }));

  const handleStatusChange = (e: SelectChangeEvent<unknown>): void =>
    setFilters(currentFilters => ({
      ...currentFilters,
      status: OpportunityStatus[e.target.value as keyof typeof OpportunityStatus],
    }));

  const showDialog = (opportunity: IOpportunity, oppAdminAction: OppAdminAction) => {
    setSelectedOpportunity(opportunity);
    setDialogType(oppAdminAction);
    setOpen(true);
  };

  return (
    <Container maxWidth="lg">
      <Grid container sx={{ mb: 2, mt: 2 }} gap={1}>
        <OppFilters
          category={category}
          categories={categories}
          setCategory={setCategory}
          setFilteredOpps={setFilteredOpps}
          opportunities={opportunities}
        >
          <Grid item xs={12} sm={3}>
            <FormSelect
              value={filters.status}
              label="Status"
              items={statusItems}
              onChange={handleStatusChange}
              size={isMobile ? 'medium' : 'small'}
            />
          </Grid>
        </OppFilters>
      </Grid>
      <Grid container>
        {loading && (
          <Grid item xs={12} sx={{ mb: 5 }}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {!loading && (
          <Grid item xs={12} sx={{ mb: 6 }}>
            {filteredOpps.map((opportunity: IOpportunity, index: number) => (
              <OpportunityAccordion key={index} opportunity={opportunity} loggedIn={true}>
                <>
                  {opportunity.status !== OpportunityStatus.Approved && (
                    <RoundButton
                      variant="contained"
                      onClick={() => {
                        showDialog(opportunity, OppAdminAction.Approve);
                      }}
                    >
                      Approve
                    </RoundButton>
                  )}
                  <RoundButton variant="outlined" onClick={() => navigate(`/admin/internal-jobs/${opportunity.id}`)}>
                    Edit
                  </RoundButton>
                  {opportunity.status !== OpportunityStatus.Rejected && (
                    <RoundButton
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        showDialog(opportunity, OppAdminAction.Reject);
                      }}
                    >
                      Reject
                    </RoundButton>
                  )}
                </>
              </OpportunityAccordion>
            ))}
          </Grid>
        )}
      </Grid>
      <OppAdminConfirmationDialog
        open={open}
        setOpen={setOpen}
        opportunity={selectedOpportunity}
        setRefresh={setRefresh}
        action={dialogType}
      />
    </Container>
  );
}
