import { Grid, useMediaQuery, useTheme } from '@mui/material';
import ActionCreateContract from './ActionCreateContract';

import ActionFindTalent from './ActionFindTalent';
import ActionRefer from './ActionRefer';

export default function MainActions() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container mt={isMobile ? 0 : 3} spacing={2}>
      <Grid item container xs={12} md={4}>
        <ActionFindTalent />
      </Grid>
      <Grid item container xs={12} md={4}>
        <ActionRefer />
      </Grid>

      <Grid item container xs={12} md={4}>
        <ActionCreateContract />
      </Grid>
    </Grid>
  );
}
