import { Avatar, Box, Stack, styled, Typography } from '@mui/material';

export const StyledBannerBox = styled(Box)(() => ({
  background: 'linear-gradient(0deg, #F6F8FE 0%, #E7E2FE 100%)',
}));

export const BannerStack = styled(Stack)(({ theme }) => ({
  paddingTop: '36px',
  paddingBottom: '36px',

  [theme.breakpoints.up('md')]: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },
}));

interface StyledAvatarProps {
  large?: boolean;
}

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'large',
})<StyledAvatarProps>(({ large }) => ({
  width: large ? '50px' : '44px',
  height: large ? '50px' : '44px',
  backgroundColor: '#E4E6FF',
}));

export const FeatureText = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: '600',
  color: theme.palette.black[500],
  letterSpacing: '-0.5px',

  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
    lineHeight: '26px',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    lineHeight: '22px',
  },
}));

export const FeatureSubText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '400',
  color: '#64666C',
  letterSpacing: '-0.5px',

  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
    lineHeight: '22px',
  },
}));

export const FeatureContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: 'white',
  gap: '10px',
  padding: '12px 16px 12px 16px',
  border: '1px solid #E5E5E5',
  borderRadius: '7px',

  boxShadow: '0px 2px 12px 0px #1721551A',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    padding: '0px 0px 0px 0px',
  },
}));

export const MultiLineFeatureContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  padding: '20px 24px 20px 24px',
  gap: '14px',
  backgroundColor: 'white',
  border: '1px solid #E5E5E5',
  borderRadius: '7px',

  boxShadow: '0px 2px 12px 0px #1721551A',
  [theme.breakpoints.down('md')]: {
    padding: '16px 20px 16px 20px',
  },
}));
