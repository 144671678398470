import { FaqOption } from 'global/interfaces/faq';

export const billingFaqOptions: FaqOption[] = [
  {
    id: '01',
    question: 'How does payment protection work? ',
    answer:
      'Shoutt offers two way payment protection to ensure sellers get paid and buyers get what they paid for. When contracts are funded by buyers, we hold onto the funds while the work gets done, then release the payment to the seller. If something goes wrong, we can step in and help. Using Shout PP is entirely optional and you’re free to deal directly. ',
  },
  {
    id: '02',
    question: 'Do you charge any commissions?',
    answer:
      'No! Shoutt is 100% commission free for both sides. A small Stripe processing fee of 1-3% (depending on the payment method) is deducted from seller payouts. ',
  },
  {
    id: '03',
    question: 'How do Shoutt contracts work?',
    answer:
      'Contracts are linked to payment protection. Our contract tools encourage both parties to be clear and concise, helping to avoid headaches down the road. We support both fixed price projects and weekly/monthly retainers with automatic payments.',
  },
  {
    id: '04',
    question: 'Are there any long term contracts?',
    answer:
      'No! You can cancel anytime by clicking on ‘My Subscription’ from your account menu. Your subscription will continue to run until the next billing cycle.',
  },
  {
    id: '05',
    question: 'How do I contact support?',
    answer: 'For any billing queries please contact support@shoutt.co.',
  },
];
