import { SvgIcon, SvgIconProps } from '@mui/material';

export const StarIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 26 26" fill="none" {...props}>
    <path
      d="M25.3891 9.98076C25.2555 9.56845 25.0026 9.20504 24.6623 8.93654C24.3221 8.66803 23.9099 8.5065 23.4778 8.47239L17.3807 7.98002L15.0277 2.29023C14.8623 1.8881 14.5812 1.54418 14.2199 1.30215C13.8587 1.06013 13.4337 0.930908 12.9989 0.930908C12.5641 0.930908 12.1391 1.06013 11.7779 1.30215C11.4167 1.54418 11.1355 1.8881 10.9702 2.29023L8.61926 7.98109L2.52221 8.47239C2.08933 8.50809 1.67666 8.67094 1.33605 8.94047C0.99545 9.21001 0.74211 9.5742 0.607869 9.98728C0.473629 10.4004 0.464477 10.8439 0.581564 11.2622C0.698651 11.6804 0.936753 12.0548 1.26595 12.3381L5.91819 16.3504L4.49602 22.3516C4.39229 22.7747 4.41683 23.2191 4.56651 23.6282C4.71619 24.0373 4.98423 24.3926 5.33652 24.6489C5.68881 24.9052 6.10939 25.0508 6.54471 25.0672C6.98004 25.0837 7.41041 24.9702 7.78103 24.7412L12.9989 21.5295L18.2168 24.7412C18.5873 24.9687 19.017 25.081 19.4514 25.0641C19.8858 25.0472 20.3055 24.9018 20.6572 24.6463C21.0089 24.3908 21.2769 24.0367 21.4273 23.6287C21.5776 23.2208 21.6036 22.7775 21.5018 22.3548L20.0796 16.3536L24.7319 12.3414C25.063 12.0573 25.3023 11.6813 25.4193 11.261C25.5364 10.8407 25.5258 10.3951 25.3891 9.98076ZM17.7966 14.9045C17.6172 15.0592 17.4838 15.2601 17.4108 15.4855C17.3378 15.7108 17.3282 15.9519 17.3829 16.1823L18.7717 22.0477L13.6766 18.9146C13.4728 18.7892 13.2382 18.7228 12.9989 18.7228C12.7596 18.7228 12.525 18.7892 12.3212 18.9146L7.22617 22.0477L8.61495 16.1866C8.66965 15.9562 8.65999 15.7151 8.58703 15.4898C8.51407 15.2645 8.38061 15.0635 8.20122 14.9088L3.64702 10.9763L9.61586 10.4947C9.85339 10.4756 10.081 10.3912 10.2736 10.2509C10.4662 10.1106 10.6163 9.9198 10.7073 9.69956L12.9989 4.15091L15.2906 9.69956C15.3815 9.9198 15.5316 10.1106 15.7242 10.2509C15.9168 10.3912 16.1445 10.4756 16.382 10.4947L22.3508 10.9763L17.7966 14.9045Z"
      fill="#4B56D8"
    />
  </SvgIcon>
);
