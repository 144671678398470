import { useContext, useEffect } from 'react';

import { AuthContext } from 'contexts/AuthContext';
import { useSearchParams } from 'react-router-dom';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { pushToDataLayer } from 'utils/tagHelper';
import { completedCheckoutEvent } from 'global/constants';

export default function TrackSubPayment(): JSX.Element {
  const authContext = useContext(AuthContext);
  const { localCurrencySymbol, currency } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('s') && authContext.user?.id !== undefined && authContext.user?.id !== null) {
      pushToDataLayer(completedCheckoutEvent, {
        transaction_id: authContext.user?.id,
        value: price,
        currency: currency,
      });
      const newParams = searchParams;
      newParams.delete('s');
      setSearchParams(newParams);
    }
  }, [authContext.user]);

  return <></>;
}
