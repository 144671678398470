import { Drawer, Grid, styled } from '@mui/material';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& .MuiPaper-root': {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
  },
}));

export const StyledDrawerGrid = styled(Grid)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}));

export const StyledGrid = styled(Grid)(() => ({
  marginTop: '16px',
}));
