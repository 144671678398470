import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import YoutubeIcon from '@mui/icons-material/YouTube';
import { TikTokIcon } from 'components/common/StyledIcons/TikTokIcon';
import { InstagramIcon } from 'components/common/StyledIcons/InstagramIcon';
import logo from './assets/images/logo/Logo.svg';
import { styled } from '@mui/material/styles';
import { helpUrl } from 'global/constants';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';

const Wrapper = styled('footer')`
  margin-top: auto;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.palette.black[500]};
  color: ${({ theme }) => theme.palette.grey[200]};
`;

const FooterBottom = styled(Box)(({ theme }) => ({
  paddingTop: '26px',
  paddingBottom: '32px',

  [theme.breakpoints.down('md')]: {
    paddingBottom: '82px',
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[50],
}));

const StyledLink = styled(Link)`
  text-decoration: none;
  position: relative;
  width: fit-content;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background: ${({ theme }) => theme.palette.grey[500]};
    transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  &:hover::after {
    width: 100%;
  }
`;

const LinkText = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[50]};
`;

const StyledIconButton = styled(IconButton)`
  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.common.white};
    &:hover {
      opacity: 0.8;
    }
  }
`;

const StyledYouTubeIcon = styled(YoutubeIcon)`
  font-size: 22px;
`;

const StyledTikTokIcon = styled(TikTokIcon)`
  font-size: 18px;
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  font-size: 18px;
`;

const StyledLogoStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  order: 0,
  alignItems: 'center',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const StyledContentStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  order: 0,
  alignItems: 'center',
  gap: theme.spacing(4),
  marginTop: '12px',

  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    order: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    justifyContent: 'space-between',
    gap: 0,
    paddingTop: '8px',
    width: '100%',
  },
}));

const StyledIconStack = styled(Stack)(({ theme }) => ({
  padding: '0 24px',
  marginTop: '0px',
  paddingRight: 0,

  [theme.breakpoints.down('md')]: {
    padding: '0',
    marginTop: 0,
  },
}));

const FooterContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
}));

const StyledLogo = styled('img')(({ theme }) => ({
  height: theme.spacing(4.5),
  cursor: 'pointer',
  filter: 'invert()',
}));

const Footer = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const handleHomeClick = (): void => {
    navigate('/');
  };

  return (
    <Wrapper>
      <Container>
        <FooterBottom>
          <FooterContainer>
            <StyledLogoStack>
              <StyledLogo onClick={handleHomeClick} alt="Shoutt" src={logo} />
            </StyledLogoStack>

            <StyledContentStack>
              <StyledLink to={authContext?.user ? '/subscriptions' : '/pricing'}>
                <LinkText variant="body2">Pricing</LinkText>
              </StyledLink>
              <StyledLink to="/privacy">
                <LinkText variant="body2">Privacy</LinkText>
              </StyledLink>

              <StyledLink to="/terms">
                <LinkText variant="body2">T&Cs</LinkText>
              </StyledLink>

              <StyledLink to="/about">
                <LinkText variant="body2">About</LinkText>
              </StyledLink>

              <StyledLink to="/contactus">
                <LinkText variant="body2">Contact</LinkText>
              </StyledLink>

              <StyledLink to={helpUrl} target="_blank">
                <LinkText variant="body2">Help</LinkText>
              </StyledLink>
            </StyledContentStack>

            <Stack
              gap={{ xs: 1, md: 6 }}
              mt={{ xs: 0, md: 1 }}
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ md: 'center' }}
            >
              <StyledIconStack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
              >
                <Link to="https://www.youtube.com/@shoutt_co" target="_blank">
                  <StyledIconButton sx={{ paddingLeft: 0 }}>
                    <StyledYouTubeIcon />
                  </StyledIconButton>
                </Link>
                <Link to="https://www.tiktok.com/@shoutt_co" target="_blank">
                  <StyledIconButton>
                    <StyledTikTokIcon />
                  </StyledIconButton>
                </Link>
                <Link to="https://www.instagram.com/shoutt_co" target="_blank">
                  <StyledIconButton sx={{ paddingRight: 0 }}>
                    <StyledInstagramIcon />
                  </StyledIconButton>
                </Link>
              </StyledIconStack>

              <BodyText variant="body2">© Shoutt International Ltd. {new Date().getFullYear()}</BodyText>
            </Stack>
          </FooterContainer>
        </FooterBottom>
      </Container>
    </Wrapper>
  );
};

export default Footer;
