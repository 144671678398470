import { BottomNavigation, Box, FormHelperText, IconButton, styled, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { CompleteProfileForm } from 'components/common/CompleteProfileForm';
import { Dispatch } from 'react';

export const StyledBottomNavigation = styled(BottomNavigation)`
  position: relative;
  border: 0;
  min-height: 105px;
`;

const TitleBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 24px;
  margin-bottom: 16px;
`;

const TitleAddDetBox = styled(TitleBox)`
  margin-bottom: 0px;
`;

export const JustifiedBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const ErrorBox = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const AddBasicDetails: React.FC<{
  closeCancel: () => void;
  setConnectionDrawerOpen?: Dispatch<boolean>;
}> = ({ closeCancel, setConnectionDrawerOpen }) => (
  <>
    <TitleAddDetBox>
      <Typography variant="h5">Complete Your Profile</Typography>
      <IconButton aria-label="Close" onClick={closeCancel}>
        <Close />
      </IconButton>
    </TitleAddDetBox>
    <Box paddingLeft={3} paddingRight={3}>
      <Typography variant="body2">
        Add a few details so sellers know who they are talking to! Can be edited anytime.
      </Typography>
    </Box>
    <CompleteProfileForm
      handleClose={closeCancel}
      setConnectionDrawerOpen={setConnectionDrawerOpen}
      isProfileDetailsInValid
    />
  </>
);
