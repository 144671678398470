import { SvgIcon, SvgIconProps } from '@mui/material';

export const PaymentProtectionIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.63604 0.95377C7.75106 0.905101 7.87491 0.880005 8.00006 0.880005C8.12522 0.880005 8.24906 0.905101 8.36409 0.95377L14.2717 3.45337C14.6042 3.59404 14.8875 3.82793 15.0865 4.12604C15.2856 4.42415 15.3916 4.77337 15.3915 5.1304V11.3862C15.3914 12.5115 15.0899 13.6167 14.5176 14.5899C13.9454 15.5631 13.1226 16.3697 12.1328 16.928L8.45833 18.9999C8.31876 19.0786 8.1608 19.12 8.00006 19.12C7.83933 19.12 7.68137 19.0786 7.5418 18.9999L3.86734 16.928C2.87722 16.3696 2.05431 15.5627 1.48202 14.5891C0.909738 13.6156 0.608429 12.51 0.608643 11.3844V5.1304C0.608683 4.77353 0.714798 4.4245 0.913844 4.12657C1.11289 3.82864 1.39612 3.59489 1.72844 3.45428L7.63604 0.95377ZM11.4251 8.82098C11.5934 8.64899 11.6865 8.41863 11.6844 8.17953C11.6823 7.94042 11.5851 7.7117 11.4138 7.54262C11.2425 7.37354 11.0108 7.27764 10.7685 7.27556C10.5263 7.27348 10.2929 7.3654 10.1186 7.53151L7.07614 10.5345L5.8815 9.35537C5.70724 9.18925 5.47386 9.09734 5.23161 9.09941C4.98936 9.10149 4.75762 9.1974 4.58632 9.36648C4.41502 9.53556 4.31785 9.76428 4.31574 10.0034C4.31364 10.2425 4.40676 10.4728 4.57506 10.6448L6.42292 12.4687C6.59618 12.6397 6.83114 12.7357 7.07614 12.7357C7.32113 12.7357 7.55609 12.6397 7.72935 12.4687L11.4251 8.82098Z"
      fill="#4B56D8"
    />
  </SvgIcon>
);
