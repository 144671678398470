import { IOpportunity } from 'global/interfaces/opportunity';

export const processOpportunityCategories = (retrievedOpps: IOpportunity[]) => {
  const cats: { [id: string]: number } = {};
  for (let i = 0; i < retrievedOpps.length; i++) {
    const opp = retrievedOpps[i];
    for (let j = 0; j < opp.categories.length; j++) {
      const c = opp.categories[j];
      if (c in cats) {
        cats[c]++;
      } else {
        cats[c] = 1;
      }
    }
  }
  const items = Object.keys(cats)
    .sort() // Sort the keys alphabetically by default
    .map(key => ({
      id: key,
      label: `${key} (${cats[key]})`,
    }));

  items.unshift({
    id: 'All',
    label: 'All',
  });
  return items;
};
