import { DashboardButton } from './DashboardButton';

import ActionBox from './ActionBox';
import { useNavigate } from 'react-router-dom';

export default function ActionFindTalent() {
  const navigate = useNavigate();
  return (
    <ActionBox
      title="Post opportunities"
      body={'Submit your project brief and chat to pre-screened freelancers, no searching required.'}
    >
      <DashboardButton variant="contained" onClick={() => navigate(`/newjob`)}>
        Post an opportunity
      </DashboardButton>
    </ActionBox>
  );
}
