import { Box, CircularProgress, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { ApplyInstructionsType, IOppApplyExternalReponse, IOpportunity } from 'global/interfaces/opportunity';
import { RoundButton } from 'components/common/Button/RoundButton';

import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { applyToExternal } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';

interface IApplyExternalOpportunity {
  open: boolean;
  opportunity: IOpportunity | null;
  updateApplyOnClose: () => void;
}

export default function ApplyExternalOpportunity(props: IApplyExternalOpportunity): JSX.Element {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);

  const [externalApplyInfo, setExternalApplyInfo] = useState<IOppApplyExternalReponse | null>(null);

  useEffect(() => {
    if (props.open && props.opportunity != null) {
      setLoading(true);
      applyToExternal(props.opportunity.id)
        .then(res => {
          setExternalApplyInfo(res);
          setLoading(false);
        })
        .catch(showError);
    }
  }, [props.opportunity, props.open]);

  return (
    <>
      {props.opportunity != null && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              Apply
            </Typography>
            <IconButton onClick={props.updateApplyOnClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            {externalApplyInfo?.type == ApplyInstructionsType.Text && !loading && (
              <>
                <Typography variant="body1">
                  To apply for the opportunity &quot;{props.opportunity.title}&quot;, please follow the instructions
                  below:
                </Typography>
                <Typography variant="body1" mt={2}>
                  {externalApplyInfo?.applyInstructions}
                </Typography>
              </>
            )}
            {externalApplyInfo?.type == ApplyInstructionsType.Url && !loading && (
              <>
                <Typography variant="body1">
                  For the opportunity &quot;{props.opportunity.title}&quot;, please apply directly via the site where we
                  sourced the opportunity{' '}
                  <span
                    style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                    onClick={() => window.open(externalApplyInfo?.applyInstructions, '_blank')}
                  >
                    here
                  </span>
                  .
                </Typography>
              </>
            )}
            {loading && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <CircularProgress />
              </Box>
            )}
          </StyledDialogContent>
          <StyledDialogActions>
            <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.updateApplyOnClose}>
              Close
            </RoundButton>
          </StyledDialogActions>
        </>
      )}
    </>
  );
}
