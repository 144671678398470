import { Grid, InputAdornment, SelectChangeEvent, styled, TextField, useMediaQuery, useTheme } from '@mui/material';
import FormSelect from 'components/common/Select/FormSelect';
import { IOpportunity } from 'global/interfaces/opportunity';
import { SelectItem } from 'global/interfaces/selects';
import { Dispatch, useState } from 'react';

interface IOppFilterProps {
  category: string | undefined;
  categories: SelectItem[];
  setCategory: Dispatch<string>;
  setFilteredOpps: Dispatch<React.SetStateAction<IOpportunity[]>>;
  opportunities: IOpportunity[];
  children?: React.ReactNode;
}

const StyledAdornment = styled(InputAdornment)(() => ({
  fieldset: {
    marginTop: '6px',
    marginBottom: '6px',
    borderTopWidth: '0px !important',
    borderBottomWidth: '0px !important',
    borderRightColor: 'rgba(0, 0, 0, 0.23) !important',
    borderRightWidth: '1px !important',
    borderLeftWidth: '0px !important',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
  },
}));

export default function OppFilters({
  category,
  categories,
  setCategory,
  setFilteredOpps,
  opportunities,
  children,
}: IOppFilterProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [search, setSearch] = useState('');

  const handleCategoryChange = (e: SelectChangeEvent<unknown>): void => {
    const selectedCat = e.target.value as string;
    setCategory(selectedCat);
    if (selectedCat === 'All') {
      setFilteredOpps(opportunities);
    } else {
      let filt = opportunities.filter(p => p.categories.includes(selectedCat));
      if (search !== '') {
        filt = filterSearch(filt, search);
      }
      setFilteredOpps(filt);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = e.target.value;
    setSearch(val);
    let filt = filterSearch(opportunities, search);

    if (category !== undefined && category !== '' && category !== 'All') {
      filt = filt.filter(p => p.categories.includes(category));
    }
    setFilteredOpps(filt);
  };

  return (
    <Grid container item xs={12} sx={{ mb: !isMobile ? 5 : 2, mt: 5 }} gap={1}>
      <Grid item xs={12} sm={5}>
        <TextField
          name="search"
          label="Search"
          value={search}
          onChange={handleSearchChange}
          placeholder="Enter Keywords"
          size={isMobile ? 'medium' : 'small'}
          fullWidth
          InputProps={{
            startAdornment: (
              <StyledAdornment position="start">
                <FormSelect
                  value={category}
                  name="category"
                  size={isMobile ? 'medium' : 'small'}
                  items={categories}
                  onChange={handleCategoryChange}
                  sx={{
                    width: !isMobile ? '150px' : '100px',
                  }}
                />
              </StyledAdornment>
            ),
          }}
        />
      </Grid>
      {children}
    </Grid>
  );
}

function filterSearch(opps: IOpportunity[], search: string) {
  return opps.filter(
    p =>
      p.description.toLowerCase().includes(search.toLowerCase()) ||
      p.title.toLowerCase().includes(search.toLowerCase()) ||
      p.location?.toLowerCase().includes(search.toLowerCase()),
  );
}
