import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { showError } from 'utils/errorHandler';

import { redirectToLogin, useTitle } from 'utils/router';
import { IOpportunity } from 'global/interfaces/opportunity';
import { getOpportunities } from 'services/opportunityService';

import { SelectItem } from 'global/interfaces/selects';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { AuthContext } from 'contexts/AuthContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ApplyDialog from 'components/opportunities/ApplyDialog';

import OpportunityAccordion from 'components/opportunities/OpportunityAccordion';
import { processOpportunityCategories } from 'components/opportunities/categories';
import { RoundButton } from 'components/common/Button/RoundButton';
import EastIcon from '@mui/icons-material/East';
import {
  BannerStack,
  FeatureSubText,
  FeatureText,
  MultiLineFeatureContainer,
  StyledAvatar,
  StyledBannerBox,
} from 'components/info/Hero';
import { ClockIcon } from 'components/icon/ClockIcon';
import { StarIcon } from 'components/icon/StarIcon';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import OppFilters from 'components/opportunities/OppFilters';

const ViewOpportunities = () => {
  useTitle('New Opportunities');
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const [filteredOpps, setFilteredOpps] = useState<IOpportunity[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [searchParams] = useSearchParams();
  const urlCategory = searchParams.get('category');

  const [category, setCategory] = useState<string>('All');

  const [categories, setCategories] = useState<SelectItem[]>([{ id: 'All', label: 'All' }]);

  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const loggedIn: boolean = authContext.user !== null;

  const [selectedOpportunity, setSelectedOpportunity] = useState<IOpportunity | null>(null);
  const [applyDialogOpen, setApplyDialogOpen] = useState<boolean>(false);

  const processCategories = (retrievedOpps: IOpportunity[]) => {
    const items = processOpportunityCategories(retrievedOpps);
    setCategories(items);
  };

  const applyNow = (opportunity: IOpportunity): void => {
    setSelectedOpportunity(opportunity);
    if (!loggedIn) {
      redirectToLogin(navigate, location);
    } else {
      setApplyDialogOpen(true);
    }
  };

  const onApplyClose = (): void => {
    setSelectedOpportunity(null);
    setApplyDialogOpen(false);
  };

  const updateOpportunity = (opportunity: IOpportunity): void => {
    setOpportunities(current => current.map(c => (c.id == opportunity.id ? opportunity : c)));
    setFilteredOpps(current => current.map(c => (c.id == opportunity.id ? opportunity : c)));
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewOpportunity],
    (res: IOpportunity) => {
      setOpportunities(current => [res, ...current]);
      if (category === 'All' || !category || category == '' || res.categories.includes(category)) {
        setFilteredOpps(current => [res, ...current]);
      }
    },
    [category],
  );

  useEffect(() => {
    processCategories(opportunities);
    if (
      urlCategory !== undefined &&
      urlCategory !== null &&
      urlCategory !== '' &&
      firstLoad &&
      opportunities.length > 0
    ) {
      setCategory(urlCategory);
      const filt = opportunities.filter(p => p.categories.includes(urlCategory));
      setFilteredOpps(filt);
      setFirstLoad(false);
    }
  }, [opportunities]);

  useEffect(() => {
    setLoading(true);
    getOpportunities()
      .then((retrievedOpps: IOpportunity[]) => {
        setOpportunities(retrievedOpps);
        setFilteredOpps(retrievedOpps);
        setLoading(false);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <StyledBannerBox>
        <Container maxWidth="lg">
          <BannerStack direction={'column'} rowGap={2} alignItems={isMobile ? 'flex-start' : 'center'}>
            <Typography
              variant="h3"
              textAlign={isMobile ? 'left' : 'center'}
              fontWeight={700}
              fontSize={{ xs: '28px', md: '44px', lg: '55px' }}
              color={theme.palette.common.black}
              maxWidth={'900px'}
            >
              {!loading ? (
                opportunities.length
              ) : (
                <Skeleton
                  variant="text"
                  width={isMobile ? '50px' : '80px'}
                  component={'span'}
                  sx={{ display: 'inline-block' }}
                />
              )}{' '}
              new freelance opportunities right here {!isMobile ? '— right now' : ''}
            </Typography>
            <Grid container mt={{ xs: '0px', md: '32px' }} spacing={isMobile ? '14px' : '18px'}>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'}>
                  <StyledAvatar large={!isMobile}>
                    <ClockIcon sx={{ width: !isMobile ? '25px' : '20px', height: !isMobile ? '25px' : '20px' }} />
                  </StyledAvatar>
                  <Stack direction={'column'} gap={'4px'}>
                    <FeatureText>Save Your Time</FeatureText>
                    <FeatureSubText>
                      We spend countless hours curating opportunities from various sources so you don’t have to.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'}>
                  <StyledAvatar large={!isMobile}>
                    <StarIcon sx={{ width: !isMobile ? '25px' : '20px', height: !isMobile ? '25px' : '20px' }} />
                  </StyledAvatar>
                  <Stack direction={'column'} gap={'4px'}>
                    <FeatureText>Get More Clients</FeatureText>
                    <FeatureSubText>
                      Our live feed is updated throughout the day so you can apply early and win more clients.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'}>
                  <StyledAvatar large={!isMobile}>
                    <ExclusiveIcon sx={{ width: !isMobile ? '25px' : '20px', height: !isMobile ? '25px' : '20px' }} />
                  </StyledAvatar>
                  <Stack direction={'column'} gap={'4px'}>
                    <FeatureText>Access Exclusive Opportunities</FeatureText>
                    <FeatureSubText>
                      List on our marketplace to access Shoutt exclusive opportunities from quality clients.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
            </Grid>
          </BannerStack>
        </Container>
      </StyledBannerBox>
      <Container maxWidth="lg">
        <Grid container>
          <OppFilters
            categories={categories}
            category={category}
            opportunities={opportunities}
            setCategory={setCategory}
            setFilteredOpps={setFilteredOpps}
          />
          {loading && (
            <Grid item xs={12} sx={{ mb: 5 }}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <CircularProgress />
              </Box>
            </Grid>
          )}
          {!loading && (
            <Grid item xs={12} sx={{ mb: 6 }}>
              {filteredOpps.map((opportunity: IOpportunity, index: number) => (
                <OpportunityAccordion key={index} opportunity={opportunity} loggedIn={loggedIn}>
                  <RoundButton
                    variant="contained"
                    endIcon={<EastIcon />}
                    onClick={() => {
                      applyNow(opportunity);
                    }}
                  >
                    Apply Now
                  </RoundButton>
                </OpportunityAccordion>
              ))}
            </Grid>
          )}
        </Grid>
        <ApplyDialog
          open={applyDialogOpen}
          opportunity={selectedOpportunity}
          onClose={onApplyClose}
          updateOpportunity={updateOpportunity}
        />
      </Container>
    </>
  );
};

export default ViewOpportunities;
