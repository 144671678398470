import { SvgIcon, SvgIconProps } from '@mui/material';

export const ClockIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 28 28" fill="none" {...props}>
    <path
      d="M14 26.5C20.9036 26.5 26.5 20.9036 26.5 14C26.5 7.09644 20.9036 1.5 14 1.5C7.09644 1.5 1.5 7.09644 1.5 14C1.5 20.9036 7.09644 26.5 14 26.5Z"
      stroke="#4B56D8"
      strokeWidth="2.77778"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14 7.05554V12.2833C14 12.7993 14.1437 13.305 14.4151 13.7439C14.6864 14.1828 15.0746 14.5374 15.5361 14.768L19.5556 16.7778"
      stroke="#4B56D8"
      strokeWidth="2.77778"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
