import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExclusiveIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 26 24" fill="none" {...props}>
    <path
      d="M15.8799 11.7895L9.17397 11.7895L7.07572 18.2619H16.0494L20.5405 23.3642H5.79559C4.56631 23.3642 3.56806 22.9502 2.80082 22.1222C2.03358 21.2942 1.64997 20.3297 1.64997 19.2264C1.64997 19.124 1.65844 19.0109 1.6754 18.8914C1.69235 18.7697 1.70083 18.6417 1.70083 18.503C1.73474 18.3664 1.76865 18.2362 1.80257 18.1167C1.83648 17.9951 1.87038 17.8841 1.90429 17.7817L3.84994 11.7853H0.448242L2.13743 6.66586H5.48826L7.43391 0.635254L12.245 2.2336L10.8123 6.66586H17.5182L15.8799 11.7917V11.7895Z"
      fill="#4B56D8"
    />
    <path
      d="M23.9162 11.7901H21.2033L19.1051 18.2625H25.1964L23.558 23.3648H17.825C16.5957 23.3648 15.5974 22.9508 14.8302 22.1228C14.063 21.2948 13.6793 20.3303 13.6793 19.227C13.6793 19.1246 13.6878 19.0115 13.7048 18.892C13.7217 18.7704 13.7302 18.6423 13.7302 18.5036C13.7641 18.367 13.798 18.2369 13.8319 18.1174C13.8659 17.9957 13.8998 17.8848 13.9337 17.7823L15.8793 11.7859H13.0117L14.7009 6.66647L17.5155 6.66647L19.4612 0.635864L24.2723 2.23421L22.8396 6.66647L25.5524 6.66647L23.9141 11.7923L23.9162 11.7901Z"
      fill="#4B56D8"
    />
  </SvgIcon>
);
