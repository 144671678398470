import { Grid, SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserConnectionSection } from './UserConnectionSection/UserConnectionSection';
import UserHourlyRateSection from './UserHourlyRateSection';
import { PackageType } from 'global/enums/packageType';
import { IFrontUser } from 'global/interfaces/user';
import { AddBasicDetails } from './UserConnectionSection/AddBasicDetails';
import { Dispatch } from 'react';

interface IViewUserPricingProps {
  user: IFrontUser;
  drawer: boolean;
  setDrawer(value: boolean): void;
  tabValue: PackageType;
  setTabValue(value: PackageType): void;
  setConnectionDrawerOpen: Dispatch<boolean>;
  openConnectionDrawer: boolean;
}

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
}));

export default function UserPricing({
  user,
  drawer,
  setDrawer,
  setConnectionDrawerOpen,
  openConnectionDrawer,
}: IViewUserPricingProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {!isMobile && (
        <UserConnectionSection
          openDetailsDrawer={() => setDrawer(true)}
          user={user}
          setOpenConnectionDrawer={setConnectionDrawerOpen}
          openConnectionDrawer={openConnectionDrawer}
          setDetailsDrawer={setDrawer}
        />
      )}

      <Grid container>
        {user.isSellingServices && Boolean(user.usdHourlyRate) && (
          <Grid item xs={12}>
            <UserHourlyRateSection
              user={user}
              setDrawer={() => {
                setDrawer(true);
              }}
            />
          </Grid>
        )}

        {!isMobile && (
          <Grid item xs={12}>
            <StyledSwipeableDrawer
              anchor={isMobile ? 'bottom' : 'right'}
              open={drawer}
              onClose={() => setDrawer(false)}
              onOpen={() => setDrawer(true)}
            >
              <AddBasicDetails closeCancel={() => setDrawer(false)} setConnectionDrawerOpen={setConnectionDrawerOpen} />
            </StyledSwipeableDrawer>
          </Grid>
        )}
      </Grid>
    </>
  );
}
