export enum MessagePublisherEventType {
  NewMessage,
  VideoProcessed,
  ReadMessages,
  UpdateThread,
  UserVettingView,
  ContractChange,
  UpdateMessage,
  ConnectionInvite,
  UserTyping,
  VettingChange,
  SubscriptionEnded,
  NewOpportunity,
}

export const ConnectionRestarted = 'ConnectionRestarted';
