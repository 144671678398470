import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import Dashboard from './dashboard/Dashboard';

import ViewOpportunities from './opportunities/ViewOpportunities';

export default function Home() {
  const authContext = useContext(AuthContext);
  return <>{authContext.user?.id !== undefined ? <Dashboard /> : <ViewOpportunities />}</>;
}
